import {
    ApplicationTypeDto,
    FormAction,
    MainProfiCardChApplicationDto,
    MainProfiCardDeApplicationDto,
    MainProfiCardLuApplicationDto
} from '../../../../api';
import {mapApplicationTypeToOriginator} from '../../../../utils/OriginatorProvider';
import {formatLocalDate, mapEnum, parseLocalDate} from '../../../../utils/utils';
import {mapToMoneyDto, mapToMoneyModel} from '../../../money/mapper';
import {findOptionEntryByKey, findPlaceOfRegistrationOptionEntryByKey, getReferences} from '../../../reference/service';
import {ApplicationStatus} from '../../model';
import {MainProfiCardApplicationWithCrefoDetailsId} from '../model';
import {BaseMainProfiCardApplication, isCompanyOwnerRequired} from './model';
import {mapPlaceOfRegistrationToRequest} from './utils';

export function mapModelToCreateBaseMainProfiCardApplicationRequest(action: FormAction, data: MainProfiCardApplicationWithCrefoDetailsId<BaseMainProfiCardApplication>) {
    return {
        action,
        desiredLimit: mapToMoneyDto(data.desiredLimit),
        company: {
            companyName: data.company?.companyName,
            legalForm: data.company?.legalForm ?? undefined,
            branchCategory: data.company?.branchCategory ?? undefined,
            branchOfIndustry: data.company?.branchOfIndustry ?? undefined,
            address: {
                additionalLine: data.company?.address?.additionalLine,
                street: data.company?.address?.street,
                houseNumber: data.company?.address?.houseNumber,
                zipCode: data.company?.address?.zipCode,
                city: data.company?.address?.city,
                country: data.company?.address?.country ?? undefined,
                verified: data.company?.address?.verified ?? undefined,
                unverifiedAddressAccepted: data.company?.address?.unverifiedAddressAccepted ?? false,
            }
        },
        billingAddress: data.isAdditionalBillingAddress !== true ? undefined : {
            contactPersonName: data.billingAddress?.contactPersonName,
            email: data.billingAddress?.email,
            cellPhoneNumber: data.billingAddress?.cellPhoneNumber,
            phoneNumber: data.billingAddress?.phoneNumber === null ? undefined : data.billingAddress?.phoneNumber,
            address: {
                street: data.billingAddress?.address?.street,
                houseNumber: data.billingAddress?.address?.houseNumber,
                zipCode: data.billingAddress?.address?.zipCode,
                city: data.billingAddress?.address?.city,
                country: data.billingAddress?.address?.country ?? undefined,
                verified: data.billingAddress?.address?.verified ?? undefined,
                unverifiedAddressAccepted: data.billingAddress?.address?.unverifiedAddressAccepted ?? false,
            }
        },
        cardHolder: {
            salutation: data.cardHolder?.salutation ?? undefined,
            title: data.cardHolder?.title ?? undefined,
            firstName: data.cardHolder?.firstName,
            lastName: data.cardHolder?.lastName,
            dateOfBirth: formatLocalDate(data.cardHolder?.dateOfBirth),
            email: data.cardHolder?.email,
            cellPhoneNumber: data.cardHolder?.cellPhoneNumber?.trim(),
            phoneNumber: data.cardHolder?.phoneNumber === null || data.cardHolder?.phoneNumber?.trim().length === 0 ?
                undefined : data.cardHolder?.phoneNumber,
        },
        companyOwner: !isCompanyOwnerRequired(data) ? undefined : {
            differentFromCardholder: data.companyOwner?.differentFromCardholder ?? true,
            salutation: data.companyOwner?.salutation ?? undefined,
            title: data.companyOwner?.title ?? undefined,
            firstName: data.companyOwner?.firstName,
            lastName: data.companyOwner?.lastName,
            dateOfBirth: formatLocalDate(data.companyOwner?.dateOfBirth),
            placeOfBirth: data.companyOwner.placeOfBirth,
            nationality: data.companyOwner?.nationality ?? undefined,
            email: data.companyOwner?.email ?? '',
            cellPhoneNumber: data.companyOwner?.cellPhoneNumber?.trim() ?? '',
            phoneNumber: data.companyOwner?.phoneNumber === null || data.companyOwner?.phoneNumber?.trim().length === 0 ?
                undefined : data.companyOwner?.phoneNumber,
            address: {
                additionalLine: data.companyOwner?.address?.additionalLine,
                street: data.companyOwner?.address?.street,
                houseNumber: data.companyOwner?.address?.houseNumber,
                zipCode: data.companyOwner?.address?.zipCode,
                city: data.companyOwner?.address?.city,
                country: data.companyOwner?.address?.country ?? undefined,
                verified: data.companyOwner.address?.verified ?? undefined,
                unverifiedAddressAccepted: data.companyOwner.address?.unverifiedAddressAccepted ?? false,
            }
        },
        marketData: {
            masterStore: data.marketData?.masterStore ?? undefined,
            pinForWebShop: data.marketData?.pinForWebShop,
        },
        cardDispatch: data.cardDispatch ?? undefined,
        legitimization: {
            idCardNumber: data.legitimization?.idCardNumber,
            dateOfIssue: formatLocalDate(data.legitimization?.dateOfIssue),
            placeOfRegistration: mapPlaceOfRegistrationToRequest(data.legitimization?.placeOfRegistration),
            issuingAuthority: data.legitimization?.issuingAuthority,
            commercialRegisterNumber: data.legitimization?.commercialRegisterNumber,
            name1Signature: data.legitimization?.name1Signature,
            name2Signature: data.legitimization?.name2Signature,
        },
        consents: data.consents,
        advertisementConsent: data.advertisementConsent,
        ignoreDuplicates: data.ignoreDuplicates,
        crefoDetailsId: data.crefoDetailsId,
    };
}

export async function mapDtoToBaseMainProfiCardModel(data: MainProfiCardDeApplicationDto | MainProfiCardChApplicationDto | MainProfiCardLuApplicationDto): Promise<BaseMainProfiCardApplication> {
    const applicationType = mapEnum<ApplicationTypeDto>(data.applicationType)!!;
    const originator = mapApplicationTypeToOriginator(applicationType);
    const references = await getReferences(originator);
    const application: BaseMainProfiCardApplication = {
        applicationType: data.applicationType as (ApplicationTypeDto.PROFI_CARD_DE_MAIN | ApplicationTypeDto.PROFI_CARD_CH_MAIN | ApplicationTypeDto.PROFI_CARD_LU_MAIN),
        applicationNumber: data.applicationNumber,
        version: data.version,
        status: mapEnum(data.status) ?? ApplicationStatus.CREATED,
        processingErrorReason: mapEnum(data.processingErrorReason),
        isCreatedBySmartSignUp: !!data.smartSignUpCrefoDetails?.id,
        desiredLimit: mapToMoneyModel(data.desiredLimit),
        accountNumber: data.accountNumber,
        cardNumber: data.cardNumber,
        company: {
            companyName: data.company?.companyName ?? '',
            legalForm: findOptionEntryByKey(references.getLegalForms(data.company?.address?.country?.key ?? applicationType), data.company?.legalForm?.key),
            branchCategory: findOptionEntryByKey(references.branchCategories, data.company?.branchCategory?.key),
            branchOfIndustry: references.findBranchOfIndustryByBranchCategoryKey(data.company?.branchCategory?.key, data.company?.branchOfIndustry?.key),
            address: {
                additionalLine: data.company?.address?.additionalLine ?? '',
                street: data.company?.address?.street ?? '',
                houseNumber: data.company?.address?.houseNumber ?? '',
                zipCode: data.company?.address?.zipCode ?? '',
                city: data.company?.address?.city ?? '',
                country: findOptionEntryByKey(references.countryCodes, data.company?.address?.country?.key),
                verified: data.company?.address?.verified,
                unverifiedAddressAccepted: data.company?.address?.unverifiedAddressAccepted ?? false,
            }
        },
        isAdditionalBillingAddress: data.billingAddress !== undefined && data.billingAddress !== null,
        billingAddress: {
            contactPersonName: data.billingAddress?.contactPersonName ?? '',
            email: data.billingAddress?.email ?? '',
            cellPhoneNumber: data.billingAddress?.cellPhoneNumber?.trim() ?? '',
            phoneNumber: data.billingAddress?.phoneNumber?.trim() ?? '',
            address: {
                street: data.billingAddress?.address?.street ?? '',
                houseNumber: data.billingAddress?.address?.houseNumber ?? '',
                zipCode: data.billingAddress?.address?.zipCode ?? '',
                city: data.billingAddress?.address?.city ?? '',
                country: findOptionEntryByKey(references.countryCodes, data.billingAddress?.address?.country?.key),
                verified: data.billingAddress?.address?.verified,
                unverifiedAddressAccepted: data.billingAddress?.address?.unverifiedAddressAccepted ?? false,
            },
        },
        cardHolder: {
            salutation: findOptionEntryByKey(references.salutations, data.cardHolder?.salutation?.key),
            title: findOptionEntryByKey(references.titles, data.cardHolder?.title?.key),
            firstName: data.cardHolder?.firstName ?? '',
            lastName: data.cardHolder?.lastName ?? '',
            dateOfBirth: parseLocalDate(data.cardHolder?.dateOfBirth),
            email: data.cardHolder?.email ?? '',
            cellPhoneNumber: data.cardHolder?.cellPhoneNumber?.trim() ?? '',
            phoneNumber: data.cardHolder?.phoneNumber?.trim() ?? '',
        },
        companyOwner: {
            differentFromCardholder: data.companyOwner?.differentFromCardholder ?? false,
            salutation: findOptionEntryByKey(references.salutations, data.companyOwner?.salutation?.key),
            title: findOptionEntryByKey(references.titles, data.companyOwner?.title?.key),
            firstName: data.companyOwner?.firstName ?? '',
            lastName: data.companyOwner?.lastName ?? '',
            dateOfBirth: parseLocalDate(data.companyOwner?.dateOfBirth),
            placeOfBirth: data.companyOwner?.placeOfBirth ?? '',
            nationality: findOptionEntryByKey(references.citizenship, data.companyOwner?.nationality?.key),
            email: data.companyOwner?.email ?? '',
            cellPhoneNumber: data.companyOwner?.cellPhoneNumber?.trim() ?? '',
            phoneNumber: data.companyOwner?.phoneNumber?.trim() ?? '',
            address: {
                additionalLine: data.companyOwner?.address?.additionalLine ?? '',
                street: data.companyOwner?.address?.street ?? '',
                houseNumber: data.companyOwner?.address?.houseNumber ?? '',
                zipCode: data.companyOwner?.address?.zipCode ?? '',
                city: data.companyOwner?.address?.city ?? '',
                country: findOptionEntryByKey(references.countryCodes, data.companyOwner?.address?.country?.key),
                verified: data.companyOwner?.address?.verified,
                unverifiedAddressAccepted: data.companyOwner?.address?.unverifiedAddressAccepted ?? false,
            },
        },
        marketData: {
            masterStore: findOptionEntryByKey(references.getShops(applicationType), data.marketData?.masterStore?.key),
            pinForWebShop: data.marketData?.pinForWebShop ?? false,
        },
        cardDispatch: mapEnum(data.cardDispatch) ?? undefined,
        legitimization: {
            idCardNumber: data.legitimization?.idCardNumber ?? '',
            dateOfIssue: parseLocalDate(data.legitimization?.dateOfIssue),
            placeOfRegistration: findPlaceOfRegistrationOptionEntryByKey(references.getPlaceOfRegistration, data),
            issuingAuthority: data.legitimization?.issuingAuthority ?? '',
            commercialRegisterNumber: data.legitimization?.commercialRegisterNumber ?? '',
            name1Signature: data.legitimization?.name1Signature ?? '',
            name2Signature: data.legitimization?.name2Signature ?? '',
        },
        consents: data.consents ?? false,
        advertisementConsent: data.advertisementConsent ?? false,
        creationDate: data.creationDate,
        accountLimit: mapToMoneyModel(data.accountLimit),
        creditValidatorLimit: mapToMoneyModel(data.creditValidatorLimit),
        riskBy: data.riskBy,
        solvencyIndex: data.solvencyIndex,
        ignoreDuplicates: data.ignoreDuplicates ?? false,
    };
    return Promise.resolve(application);
}
