/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum Role {
    HORNBACH = 'HORNBACH',
    HORNBACH_CENTRAL = 'HORNBACH_CENTRAL',
    KNISTR = 'KNISTR',
    HRB_SERVICE_TOOL = 'HRB_SERVICE_TOOL',
    HRB_GERMANY = 'HRB_GERMANY',
    HRB_SWITZERLAND = 'HRB_SWITZERLAND',
    HRB_LUXEMBURG = 'HRB_LUXEMBURG',
}
