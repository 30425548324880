import {
    ApplicationTypeDto,
    CreateMainProjektWeltApplicationRequest,
    FormAction,
    MainProjektWeltApplicationDto,
    PaymentMethodDto,
    UpdateMainProjektWeltApplicationRequest
} from '../../../../api';
import {mapApplicationTypeToOriginator} from '../../../../utils/OriginatorProvider';
import {formatLocalDate, isAnyPropertyNotEmpty, mapEnum, parseLocalDate} from '../../../../utils/utils';
import {mapToMoneyModel} from '../../../money/mapper';
import {isOptionEntry} from '../../../reference/model';
import {findOptionEntryByKey, getReferences} from '../../../reference/service';
import {addIbanPrefix, PaymentMethod, removeIbanPrefix} from '../../iban/model';
import {ApplicationStatus} from '../../model';
import {MainProjektWeltApplication} from './model';

export function mapModelToCreateMainProjektWeltApplicationRequest(action: FormAction, data: MainProjektWeltApplication): CreateMainProjektWeltApplicationRequest {
    const applicationType = ApplicationTypeDto.PROJEKT_WELT_MAIN;
    return {
        applicationType: applicationType,
        action,
        cardHolder: {
            salutation: data.cardHolder?.salutation ?? undefined,
            title: data.cardHolder?.title ?? undefined,
            firstName: data.cardHolder?.firstName,
            lastName: data.cardHolder?.lastName,
            dateOfBirth: formatLocalDate(data.cardHolder?.dateOfBirth),
            placeOfBirth: data.cardHolder?.placeOfBirth ?? '',
            nationality: data.cardHolder?.nationality ?? undefined,
            email: data.cardHolder?.email ?? '',
            cellPhoneNumber: data.cardHolder?.cellPhoneNumber ?? '',
            phoneNumber: data.cardHolder?.phoneNumber ?? '',
            address: {
                additionalLine: data.cardHolder?.address?.additionalLine ?? '',
                street: data.cardHolder?.address?.street ?? '',
                houseNumber: data.cardHolder?.address?.houseNumber ?? '',
                zipCode: data.cardHolder?.address?.zipCode ?? '',
                city: data.cardHolder?.address?.city ?? '',
                country: data.cardHolder?.address?.country ?? undefined,
                verified: data.cardHolder?.address?.verified ?? undefined,
                unverifiedAddressAccepted: data.cardHolder?.address?.unverifiedAddressAccepted ?? false,
            },
        },
        grossHouseholdIncomePerYear: mapEnum(data.grossHouseholdIncomePerYear),
        bankDetails: {
            paymentMethod: PaymentMethodDto.DEBIT_CHARGE,
            iban: addIbanPrefix(data.bankDetails?.iban),
            bic: data.bankDetails?.bic,
            bankName: data.bankDetails?.bankName,
            bankCity: data.bankDetails?.bankCity,
        },
        marketData: {
            masterStore: data.marketData?.masterStore ?? undefined,
            pinForWebShop: data.marketData?.pinForWebShop,
        },
        legitimation: {
            idCardNumber: data.legitimation?.idCardNumber,
            dateOfIssue: formatLocalDate(data.legitimation?.dateOfIssue),
            issuingAuthority: data.legitimation?.issuingAuthority,
            employeeName: data.legitimation?.employeeName,
        },
        consents: data.consents,
        advertisementConsent: data.advertisementConsent,
        ignoreDuplicates: data.ignoreDuplicates,
        additionalCardData: !data.hasAdditionalCardData ? undefined : {
            salutation: data.additionalCardData?.salutation ?? undefined,
            title: data.additionalCardData?.title ?? undefined,
            firstName: data.additionalCardData?.firstName ?? '',
            lastName: data.additionalCardData?.lastName ?? '',
            dateOfBirth: formatLocalDate(data.additionalCardData?.dateOfBirth),
            email: data.additionalCardData?.email ?? '',
            cellPhoneNumber: data.additionalCardData?.cellPhoneNumber ?? '',
        }
    };
}

export function mapModelToUpdateMainProjektWeltApplicationRequest(action: FormAction, data: MainProjektWeltApplication): UpdateMainProjektWeltApplicationRequest {
    return {
        ...mapModelToCreateMainProjektWeltApplicationRequest(action, data),
        applicationNumber: data.applicationNumber!!,
        version: data.version,
        applicationType: ApplicationTypeDto.PROJEKT_WELT_MAIN,
    };
}

export async function mapDtoToMainProjektWeltModel(data: MainProjektWeltApplicationDto): Promise<MainProjektWeltApplication> {
    const applicationType = ApplicationTypeDto.PROJEKT_WELT_MAIN;
    const originator = mapApplicationTypeToOriginator(applicationType);
    const references = await getReferences(originator);
    const application: MainProjektWeltApplication = {
        applicationNumber: data.applicationNumber,
        version: data.version,
        applicationType: applicationType,
        status: mapEnum(data.status) ?? ApplicationStatus.CREATED,
        processingErrorReason: mapEnum(data.processingErrorReason),
        accountNumber: data.accountNumber,
        cardNumber: data.cardNumber,
        cardHolder: {
            salutation: isOptionEntry(data.cardHolder?.salutation) ? data.cardHolder?.salutation : undefined,
            title: isOptionEntry(data.cardHolder?.title) ? data.cardHolder?.title : undefined,
            firstName: data.cardHolder?.firstName ?? '',
            lastName: data.cardHolder?.lastName ?? '',
            dateOfBirth: parseLocalDate(data.cardHolder?.dateOfBirth),
            placeOfBirth: data.cardHolder?.placeOfBirth ?? '',
            nationality: findOptionEntryByKey(references.citizenship, data.cardHolder?.nationality?.key),
            email: data.cardHolder?.email ?? '',
            cellPhoneNumber: data.cardHolder?.cellPhoneNumber ?? '',
            phoneNumber: data.cardHolder?.phoneNumber ?? '',
            address: {
                additionalLine: data.cardHolder?.address?.additionalLine ?? '',
                street: data.cardHolder?.address?.street ?? '',
                houseNumber: data.cardHolder?.address?.houseNumber ?? '',
                zipCode: data.cardHolder?.address?.zipCode ?? '',
                city: data.cardHolder?.address?.city ?? '',
                country: findOptionEntryByKey(references.countryCodes, data.cardHolder?.address?.country?.key),
                verified: data.cardHolder?.address?.verified,
                unverifiedAddressAccepted: data.cardHolder?.address?.unverifiedAddressAccepted ?? false,
            },
        },
        grossHouseholdIncomePerYear: mapEnum(data.grossHouseholdIncomePerYear),
        bankDetails: {
            paymentMethod: PaymentMethod.DEBIT_CHARGE,
            iban: removeIbanPrefix(data.bankDetails?.iban ?? ''),
            bic: data.bankDetails?.bic,
            bankName: data.bankDetails?.bankName,
            bankCity: data.bankDetails?.bankCity,
        },
        marketData: {
            masterStore: isOptionEntry(data.marketData?.masterStore) ? data.marketData?.masterStore : undefined,
            pinForWebShop: data.marketData?.pinForWebShop ?? false,
        },
        legitimation: {
            idCardNumber: data.legitimation?.idCardNumber ?? '',
            dateOfIssue: parseLocalDate(data.legitimation?.dateOfIssue),
            issuingAuthority: data.legitimation?.issuingAuthority ?? '',
            employeeName: data.legitimation?.employeeName ?? '',
        },
        consents: data.consents ?? false,
        advertisementConsent: data.advertisementConsent ?? false,
        ignoreDuplicates: data.ignoreDuplicates ?? false,
        creationDate: data.creationDate,
        accountLimit: mapToMoneyModel(data.accountLimit),
        creditValidatorLimit: mapToMoneyModel(data.creditValidatorLimit),
        hasAdditionalCardData: data.additionalCardData && isAnyPropertyNotEmpty(data.additionalCardData),
        additionalCardData: {
            salutation: isOptionEntry(data.additionalCardData?.salutation) ? data.additionalCardData?.salutation : undefined,
            title: isOptionEntry(data.additionalCardData?.title) ? data.additionalCardData?.title : undefined,
            firstName: data.additionalCardData?.firstName ?? '',
            lastName: data.additionalCardData?.lastName ?? '',
            dateOfBirth: parseLocalDate(data.additionalCardData?.dateOfBirth) ?? undefined,
            email: data.additionalCardData?.email ?? '',
            cellPhoneNumber: data.additionalCardData?.cellPhoneNumber?.trim() ?? '',
        }
    };
    return Promise.resolve(application);
}
