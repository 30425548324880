/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ApplicationTypeDto {
    PROFI_CARD_DE_MAIN = 'PROFI_CARD_DE_MAIN',
    PROFI_CARD_DE_ADDITIONAL = 'PROFI_CARD_DE_ADDITIONAL',
    PROFI_CARD_LU_MAIN = 'PROFI_CARD_LU_MAIN',
    PROFI_CARD_LU_ADDITIONAL = 'PROFI_CARD_LU_ADDITIONAL',
    PROFI_CARD_CH_MAIN = 'PROFI_CARD_CH_MAIN',
    PROFI_CARD_CH_ADDITIONAL = 'PROFI_CARD_CH_ADDITIONAL',
    PROJEKT_WELT_MAIN = 'PROJEKT_WELT_MAIN',
    PROJEKT_WELT_ADDITIONAL = 'PROJEKT_WELT_ADDITIONAL',
}
