/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum CrefoInteractionStateDto {
    NOT_STARTED = 'NOT_STARTED',
    SEARCH_FAILED = 'SEARCH_FAILED',
    ZERO_RESULTS = 'ZERO_RESULTS',
    ONE_RESULT = 'ONE_RESULT',
    MANY_RESULTS = 'MANY_RESULTS',
}
