/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EndpointsDto } from '../models/EndpointsDto';
import type { User } from '../models/User';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AuthResourceService {
    /**
     * @returns EndpointsDto OK
     * @throws ApiError
     */
    public static endpointsDto(): CancelablePromise<EndpointsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/endpoints',
        });
    }
    /**
     * @returns User OK
     * @throws ApiError
     */
    public static userInfo(): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/whoami',
        });
    }
}
