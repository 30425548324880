/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddressSearchDataRequest } from '../models/AddressSearchDataRequest';
import type { AddressSuggestionsResponse } from '../models/AddressSuggestionsResponse';
import type { BankDetails } from '../models/BankDetails';
import type { CustomerInfoDto } from '../models/CustomerInfoDto';
import type { OriginatorDto } from '../models/OriginatorDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AuxiliaryResourceService {
    /**
     * @param originator
     * @param requestBody
     * @returns AddressSuggestionsResponse OK
     * @throws ApiError
     */
    public static getAddressSuggestions(
        originator: OriginatorDto,
        requestBody?: AddressSearchDataRequest,
    ): CancelablePromise<AddressSuggestionsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auxiliary/address-suggestions/{originator}',
            path: {
                'originator': originator,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param iban
     * @param originator
     * @returns BankDetails OK
     * @throws ApiError
     */
    public static bankDetails(
        iban: string,
        originator: OriginatorDto,
    ): CancelablePromise<BankDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auxiliary/bankdetails/{originator}/{iban}',
            path: {
                'iban': iban,
                'originator': originator,
            },
        });
    }
    /**
     * @param cardNumber
     * @param originator
     * @returns CustomerInfoDto OK
     * @throws ApiError
     */
    public static customerInfo(
        cardNumber: string,
        originator: OriginatorDto,
    ): CancelablePromise<CustomerInfoDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auxiliary/customer-info/{originator}/{cardNumber}',
            path: {
                'cardNumber': cardNumber,
                'originator': originator,
            },
        });
    }
}
