/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SchufaInteractionDto } from '../models/SchufaInteractionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SchufaResourceService {
    /**
     * @param applicationNumber
     * @returns SchufaInteractionDto OK
     * @throws ApiError
     */
    public static getInteractionsByApplicationNumber(
        applicationNumber: string,
    ): CancelablePromise<Array<SchufaInteractionDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/schufa/interactions/{applicationNumber}',
            path: {
                'applicationNumber': applicationNumber,
            },
        });
    }
}
