/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Language } from '../models/Language';
import type { OriginatorDto } from '../models/OriginatorDto';
import type { ReferencesDto } from '../models/ReferencesDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ReferencesResourceService {
    /**
     * @param language
     * @param originator
     * @returns ReferencesDto OK
     * @throws ApiError
     */
    public static getAll(
        language: Language,
        originator: OriginatorDto,
    ): CancelablePromise<ReferencesDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/references/{originator}/{language}',
            path: {
                'language': language,
                'originator': originator,
            },
        });
    }
}
